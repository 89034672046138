import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

import slide from './directive/slide';
import fade from './directive/fade';

// 기본 url
axios.defaults.baseURL = 'https://admin.iptk.co.kr/api';

const app = createApp(App);
app.directive("slide", slide);
app.directive("fade", fade);
app.config.globalProperties.axios = axios;
app.use(store).use(router).mount('#app');