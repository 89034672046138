export default {
	namespaced: true,
	state: {
		tags: [
			// 기업 태그 목록
			'ENERGY',
			'STARTUP STUDIO',
			'HEALTHCARE',
			'HCI',
			'FOOD',
			'REAL ESTATE',
			'MULTIMEDIA SERVIC',
			'BEAUTY',
			'BLOCKCHAIN',
			'IT INFRASTRUCTURE',
			'A.I',
		],
		company: [
			// 기업 목록
			{
				tag: 'ENERGY',
				img: require('@/assets/image/sub/portfolio/corp/passorder.png'),
				img_alt: 'passorder',
				name: '페이타랩',
				content: '스마트 비대면 주문 어플리케이션',
				homepage: 'https://passorder.co.kr/',
				twitter: '',
				facebook: '',
				instagram: 'https://www.instagram.com/passorder_official/',
				naver: 'https://blog.naver.com/paytalab',
			},
			{
				tag: 'STARTUP STUDIO',
				img: require('@/assets/image/sub/portfolio/corp/jkteck.png'),
				img_alt: 'jkteck',
				name: 'JKTeck',
				content: '보드마카&차세대 빔프로젝터 스크린필름',
				homepage: 'http://jkteck.co.kr/',
				twitter: '',
				facebook: 'https://www.facebook.com/boamarkers/',
				instagram: 'https://www.instagram.com/jkteck_boa/',
			},
			{
				tag: 'ENERGY',
				img: require('@/assets/image/sub/portfolio/corp/ndsoft.png'),
				img_alt: 'ndsoft',
				name: 'ndsoft',
				content: '메신저 글로벌 플랫폼 개발사',
				homepage: 'http://www.nd1.co.kr/',
				twitter: '',
				facebook: '',
			},
			{
				tag: 'ENERGY',
				img: require('@/assets/image/sub/portfolio/corp/waynehills.jpg'),
				img_alt: 'waynehills',
				name: '웨인힐스브라이언트AI',
				content: 'AI TTV(Text To Video) 소프트웨어 개발사 I TTV(Text To Video) 소프트웨어 개발사',
				homepage: 'https://www.waynehills.co/',
				twitter: '',
				facebook: 'https://www.facebook.com/rakudabi',
				instagram: 'https://www.instagram.com/ysuemin/',
			},
			{
				tag: 'ENERGY',
				img: require('@/assets/image/sub/portfolio/corp/shuket.png'),
				img_alt: 'shuket',
				name: '슈켓',
				content: '마트 O2O 어플리케이션 운영 개발사',
				homepage: 'http://shuket.co.kr/',
				twitter: '',
				facebook: '',
			},
			{
				tag: 'ENERGY',
				img: require('@/assets/image/sub/portfolio/corp/bettermonday.png'),
				img_alt: 'bettermonday',
				name: '베러먼데이코리아',
				content: '현대인 라이프 스타일 플랫폼',
				homepage: 'https://bettermonday.company/',
				twitter: '',
				facebook: '',
			},
			{
				tag: '더스윙',
				img: require('@/assets/image/sub/portfolio/corp/swing.png'),
				img_alt: 'reverselab',
				name: '더스윙',
				content: '공유 모빌리티',
				homepage: 'https://swingmobility.co/',
				twitter: '',
				facebook: '',
			},
			{
				tag: 'STARTUP STUDIO',
				img: require('@/assets/image/sub/portfolio/corp/thegong.svg'),
				img_alt: 'thegong',
				name: '보라공사',
				content: '공사전문 플랫폼 서비스',
				homepage: 'https://www.thegongsa.com/',
				twitter: '',
				facebook: '',
			},
			{
				tag: 'ENERGY',
				img: require('@/assets/image/sub/portfolio/corp/st1.gif'),
				img_alt: 'st1',
				name: '에스티원',
				content: '3차원 세포배양 및 재생의료제품 개발사',
				homepage: 'https://st-1.co.kr/',
				twitter: '',
				facebook: '',
			},
			{
				tag: 'ENERGY',
				img: require('@/assets/image/sub/portfolio/corp/popcornSAR.jpg'),
				img_alt: 'PopcornSAR',
				name: 'PopcornSAR',
				content: 'AUTOSAR Adaptive 플랫폼',
				homepage: 'https://www.autosar.io/main/home',
				twitter: '',
				facebook: '',
			},
			{
				tag: 'ENERGY',
				img: require('@/assets/image/sub/portfolio/corp/iliasAI.jpg'),
				img_alt: 'iliasAI',
				name: '일리아스AI',
				content: 'AI 후각지능 기반 디지털 후각 데이터',
				homepage: 'https://kr.iliasai.com/',
				twitter: '',
				facebook: '',
			},
			{
				tag: 'ENERGY',
				img: require('@/assets/image/sub/portfolio/corp/N2Ematerials.jpg'),
				img_alt: 'N2Ematerials',
				name: '엔투이머트리얼즈',
				content: '이차전지 양극재 리튬 바닥재 생산 개발',
				homepage: 'https://www.n2ematerials.com/',
				twitter: '',
				facebook: '',
			},
			// {
			// 	tag: 'ENERGY',
			// 	img: require('@/assets/image/sub/portfolio/corp/dparts.svg'),
			// 	img_alt: 'dparts',
			// 	name: '디파츠',
			// 	content: '플랫폼 기반의 럭셔리카 직수입/판매 및 차량공유 서비스',
			// 	homepage: 'https://dparts.kr/',
			// 	twitter: '',
			// 	facebook: 'https://www.facebook.com/dparts.kr',
			// 	instagram: 'https://www.instagram.com/d.parts/',
			// 	naver: 'https://blog.naver.com/troyflow',
			// },
		],
	},
	mutations: {},
};
